import React from 'react'
import {useSpring} from 'react-spring'
import {AnimatedButtonProps} from 'src/components/AnimatedButton'
import ProductButton from 'src/components/ProductButton'
import LinksLayout from 'src/components/LinksLayout'
import {fadeInFromLeftConfig, fadeInFromRightConfig} from 'src/utils/animations'

const Monitors = (props) => {
  const fadeInFromLeft = useSpring(fadeInFromLeftConfig)
  const fadeInFromRight = useSpring(fadeInFromRightConfig)

  const productList: AnimatedButtonProps[] = [
    {
      backgroundImageData: props.data.Monitor.childImageSharp.fluid,
      link: 'https://amzn.to/3kLUSiW',
      fadeSpringClass: fadeInFromLeft,
      header: '34” LG Ultrawide 1440p 160Hz',
    },
    {
      backgroundImageData: props.data.VerticalMonitor.childImageSharp.fluid,
      link: 'https://amzn.to/3sm1fO1',
      fadeSpringClass: fadeInFromRight,
      header: '27” Dell 1440p 75Hz',
    },
    {
      backgroundImageData: props.data.TV.childImageSharp.fluid,
      link: 'https://amzn.to/3FxlKwz',
      fadeSpringClass: fadeInFromLeft,
      header: '55” TCL 4K TV',
    },
  ]

  return (
    <LinksLayout>
      {productList.map((linkData) => <ProductButton {...linkData}/> )}
    </LinksLayout>
  )
}

export default Monitors

export const query = graphql`
  query {
    Monitor: file(relativePath: { eq: "setup/monitors/monitor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    VerticalMonitor: file(relativePath: { eq: "setup/monitors/verticalmonitor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    TV: file(relativePath: { eq: "setup/monitors/tv.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Case: file(relativePath: { eq: "setup/pcbuild/case.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    AIO: file(relativePath: { eq: "setup/pcbuild/aio.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Motherboard: file(relativePath: { eq: "setup/pcbuild/motherboard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`